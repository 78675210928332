.card-service {
    height: 100%;
    border: none;
    
}
.card-service .card-title {
   font-size: 17px;
   font-weight: 600;
}
.card-service .card-body {
  text-align: center;
  padding-left: 17px;
  padding-right: 17px;
 }
 .card-service .card-body p {
     font-weight: 300;
 }
.card-service .card-footer {
    background-color: transparent;
    padding-top: 0;
    border: none;
    text-align: center;
}

.card-subpage {
    border: none;
    border-radius: 0;
}