.layout-header {
    justify-content: space-between;
   
}
.layout-header.bg-white {
    border-bottom: 1px solid rgba(1,1,1,0.1);
    box-shadow: 2px 0 6px rgba(1,1,1,0.1);
}

.layout-header.navbar-light .navbar-nav .nav-link {
   font-weight: 600;
    color: #3da3e5;
}
.icon-service{
    padding: 10px;
}
.services-button:hover{
    box-shadow: 2px 2px;
}