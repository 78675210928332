main > .container {
    padding: 60px 15px 0;
  }
  
  .footer {
    background-color: #333333;
    color: #fff;
    font-size: 14px;
   
  }
  .footer-top {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .footer > .container {
    padding-right: 15px;
    padding-left: 15px;
  }
  
  code {
    font-size: 80%;
  }

  .footer-image {
      width: 104px;
      height: 104px;
      background-color: #fff;
  }

  .footer-link-list {
    padding-left: 15px;
  }
  .footer-link-list li  a {
    color: #fff;
  }
  .footer-bottom a.link-blue {
    color: #37a0e4;
  }
  .footer-bottom {
    background-color: #000000;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .footer-bottom a {
    color: #fff;
    margin: 0 8px;
  }