.icon-service {
    background-color: #010101;
    width: 75px;
    height: 75px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
}
.icon-social {
    width: 35px;
    height: 35px;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    background-size: 100%;
}
.icon-social-button {
    width: 290px;
    height: 50px;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    background-size: 100%;
}
.icon-sticky {
    width: 40px;
    height: 40px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #37a0e4;
    display: inline-block;
    border-right: 1px solid #000;

}

.icon-job{
    background-image: url('../images/icon-service-1.png');
}
.icon-resume{
    background-image: url('../images/icon-service-2.png');
}
.icon-interviewer{
    background-image: url('../images/icon-service-3.png');
}
.icon-referel{
    background-image: url('../images/icon-service-4.png');
}

.icon-social-facebook {
    background-image: url('../images/icon-f.png');
}
.icon-social-in {
    background-image: url('../images/icon-in.png');
}
.icon-social-youtube {
    background-image: url('../images/icon-youtube.png');
}
.icon-social-insta {
    background-image: url('../images/icon-insta.png');
}
.icon-profile{
    background-image: url('../images/icon-profile.png');
}
.icon-suitecase {
    background-image: url('../images/icon-suitecase.png'); 
}

.icon-social-button.icon-social-facebook{
    background-image: url('../images/button-facebook.png');
}
.icon-social-button.icon-social-in{
    background-image: url('../images/button-linked.png');
}
.icon-social-button.icon-social-gplus{
    background-image: url('../images/button-google.png');
}
/*
.icon-calendar {
    background-image: url('../images/icon-calendar.png');
}
.icon-map {
    background-image: url('../images/icon-map.png');
}
.icon-bell {
    background-image: url('../images/icon-bell.png');
}



 .button-remainme.disabled .icon-bell {
    background-image: url('../images/icon-bell-line.png');
}*/