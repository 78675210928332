@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'assets/css/header.css';
@import 'assets/css/color.css';
@import 'assets/css/typography.css';
@import 'assets/css/icon.css';
@import 'assets/css/card.css';
@import 'assets/css/button.css';
@import 'assets/css/footer.css';
@import 'assets/css/hotresource.css';
@import 'assets/css/form.css';
html {
    position: relative;
    min-height: 100%;
}

body {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
}

.container {
    max-width: inherit;
}

.layout-header.navbar-light .navbar-nav .nav-link.active {
    color: #fff;
}
.layout-header.navbar-light.bg-white .navbar-nav .nav-link.active {
    color: #333333;
}
.sociallogincnt{
    text-align: center;
    margin-right: 129px;
}
.page-group {
    padding-top: 0;
}

.page-section {
    padding-top: 35px;
    padding-bottom: 35px;
}

.service-text {
    font-size: 18px;
    color: #333333;
    padding-left: 20px;
    font-weight: 700;
    width: calc(100% - 75px);
    
}

.services-button {
    background-color: #37a0e4;
}

.service-row {
    padding-top: 24px;
}

.page-section-rewards .media-image {
    width: 76px;
    height: 76px;
    border-radius: 50%;
    border: 3px solid rgba(1, 1, 1, .3);
    text-align: center;
    line-height: 65px;
}

.page-section-rewards .media-body {
    padding-left: 15px;
}

.page-section-about {
    position: relative;
}

.page-section-about::before {
    content: '';
    width: 100%;
    height: calc(100% - 35px);
    background-color: rgba(0, 0, 0, 0.88);
    position: absolute;
    z-index: 1;
}

.img-fullwidth {
    width: 100%;
}

.img-about {
    position: relative;
    display: block;
}

.overlay-container {
    position: absolute;
    color: #fff;
    top: 0;
    z-index: 1;
    width: 100%;
    padding-top: 116px;
    padding-bottom: 64px;
}

.section-client-logo {
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -5px;
    margin-right: -5px;
}

.client-logo-col {
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
}

.client-logo {
    border: 1px solid #cccccc;
    padding: 24px 20px;
    margin-bottom: 20px;
}

.page-section-testimonials .media-image {
    border: 1px solid #696969;
}

.page-section-testimonials .media-body {
    padding-left: 30px;
}

.flex-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
}

#video-carousel .item-video video {
    object-fit: cover;
}

#video-carousel .item-video {
    height: 500px;
}

.page-section-carousel {
    position: relative;
}

.page-section-carousel video,
.page-section-carousel .carousel-image {
    /* position: absolute; */
    object-fit: cover;
}

.container-carousel .owl-item .item .container {
    position: absolute;
    top: 32%;
    left: 25px;
    right: 25px;
    z-index: 1;
}
.container-carousel .owl-item .item::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.50);
    z-index: 1;
}
.subpage-group  .page-section-carousel::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.50);
    z-index: 1;
}

.container-carousel {
    color: #fff;
    padding-top: 0;
    position: relative;
    background-color: #000;
    position: relative;
    /* position: absolute;
    z-index: 1;
    top: 0; */
    width: 100%;
    display: flex;

}

.container-carousel h1 {

    display: inline-block;
}

.page-section-hot-resources {

position: relative;
z-index: 1;

}

.hot-resource-form{
    height: 50px;
    padding-left: 50px;
    padding-right: 50px;
    color: #fff;
}
.hotcont{
    height: 30px;
    color: #fff;
    background-color: #00000079;
}
.httext{
    margin-left: 22px;
    font-family: "Roboto";
    font-size: large;
    padding-right: 20px;
}
.httext1{
    margin-left: 40px;
    padding-right: 53px;
    font-family: "Roboto";
    font-size: large;
}
.rewards-image {
    max-width: 45px;
}
.jbl{
    font-size: 20px;
    font-family: "Roboto";
}
.jbl2{
    font-size: 15px;
    font-family: "Roboto";
}

.page-section-firstchild {
    position: relative;
    z-index: 1;
}
.page-section-firstchild h1 {
    color: #fff;
    font-weight: bold;
}

.text-bold {
    font-weight: 700;
}

.link-email {
    background: url('assets/images/icon-mail.png') no-repeat left;
    background-size: 20px;
    padding-left: 30px;
}

.country-code {
    width: 86px;
    margin-right: 10px;
    border-color: #ced4da;
}

.contact-button .btn {
    min-width: 100px;
    margin-right: 20px;
}


.bold {
    font-weight: 700;
}

.button-map {
    background-color: #3da3e5;
    width: 94px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    color: #fff;
    text-align: center;
    vertical-align: middle;
}
.button-map:hover {
    text-decoration: none;
    color: #fff;
}
.button-remainme {
    border: 1px solid #3da3e5;
    color: #3da3e5;
    display: inline-block;
    height: 40px;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    display: none;
    
    
}
.button-remainme:hover {
    text-decoration: none;
}
.button-remainme .icon-bell {  
    width: 38px;
    height: 38px;
    display: inline-block;
    background-color: #3da3e5;
    vertical-align: middle;
    margin-left: -1px;   
    background-repeat: no-repeat;
    background-position: center;
}
.button-remainme.disabled {
    border-color:#c5c5c5;
    color: #c5c5c5;
}
.button-remainme.disabled .icon-bell {
    background-color: #c5c5c5;   
    background-repeat: no-repeat;
    background-position: center;
}
.button-text {
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
    vertical-align: middle;

}

.divider {
    border-bottom: 1px dotted #7f7f7f;
    padding-top: 36px;
    padding-bottom: 36px;
}
.divider:first-child {
    padding-top: 0 !important;
}

.sticky-right {
    position: fixed;
    right: -9px;
    top: 215px;
    z-index: 1;
    display: flex;
    flex-direction: column;
}

.button-top {
    position: fixed;
    right: 0;
    bottom: 15px;
    width: 40px;
    height: 40px;
    z-index: 10;
    background-color: #37a0e4;
    background-image: url('assets/images/arrow-top.png'); 
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    display: none !important;
    border: none;
    background-size: 40%;
}
.button-top.active {
    display: block !important;
}

.sticky-right a{
    background-color: #37a0e4;
    margin-bottom: 15px;
   position: relative;
   right: -142px;
   color: #000;
   text-decoration: none;
   padding-right: 10px;
   font-weight: 700;
}
.sticky-right a:hover {
    right: 0;
}
.sticky-right a:last-child {
    margin-bottom: 0;
}
.sticky-text {
    padding-left: 10px;
    padding-right: 10px;;
}

.hot-jobs-container .owl-nav{
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;

}
.hot-jobs-container .owl-nav .owl-prev, .hot-jobs-container .owl-nav .owl-next{
    position: absolute;
    color: #fff !important;
    font-size: 32px !important;
}
.hot-jobs-container .owl-nav .owl-prev:hover, 
.hot-jobs-container .owl-nav .owl-next:hover {
    background-color: transparent !important;
}

.hot-jobs-container .owl-nav .owl-next {
    right: -25px;
}
.hot-jobs-container .owl-nav .owl-prev {
    left: -25px;
}
.home-video {
    width: 100%;
    height: 580px;
    position: relative;
    opacity: .5;
}

.container-carousel-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 180px;
    z-index: 1;
}

.jobs-carousel .splide__arrow--prev,
.jobs-carousel .splide__arrow--next {
    background-color: transparent;
}
.jobs-carousel .splide__arrow svg
{
fill: #fff;
}
.jobs-carousel .splide__arrow--prev {
    left: -30px;
}
.jobs-carousel .splide__arrow--next {
    right: -30px;
}

.google-map{
    width: 100%;
    height: 450px;
    border: none;
}

.card-login {
    min-height: 480px;
} 

.page-section-testimonials .splide__arrow--prev {
    left: -45px;
}

.page-section-testimonials .splide__arrow--next {
    right: -45px;
}
.logintext{
    margin-bottom: 5px;
}
.error_msg{
    float: right;
}
.invalid_pas{
    height: 45px;
    width: 300px;
    margin: 10px 10px;
    float: center;
    background-color: #37a0e4;
    float: right;
    text-align: center;
    padding: 10px 0px;
    font-weight: 400;
    font-size: 1rem;
    color: #fff;
    border-radius: 4px;
}
.invalid_err{
    height: 45px;
    width: 300px;
    margin: 10px 10px;
    float: center;
    background-color: #e74141;
    float: right;
    text-align: center;
    padding: 10px 0px;
    font-weight: 400;
    font-size: 1rem;
    color: #fff;
    border-radius: 4px;
}
.invalid_warn{
    height: 45px;
    width: 300px;
    margin: 10px 10px;
    float: center;
    background-color: #e7d641;
    float: right;
    text-align: center;
    padding: 10px 0px;
    font-weight: 400;
    font-size: 1rem;
    color: #fff;
    border-radius: 4px;
}
.pagination{
    justify-content: flex-end;
}
.splide__pagination__page{
    visibility: hidden;
}
.table{
    width: 1100px;
} 
.table .tr .td .th{
    overflow: hidden;
}
.react-bootstrap-table-pagination-total{
    font-size: 14px;
}
.resetbtn{
    background-color: rgb(255, 103, 103);
    border: rgba(255, 103, 103, 0.637);
    height: 38px;
}
.resetbtn:hover{
    background-color: rgb(255, 37, 37);
    border: rgb(255, 37, 37);
}
.inps{
    border-radius: 3px;
    color: hsl(0, 0%, 50%);
}
.css-g1d714-ValueContainer{
    flex-wrap: nowrap !important;
    -webkit-flex-wrap: nowrap;
    overflow-x: auto !important;
    overflow-x: hidden !important;
}
.table-bordered td, .table-bordered th{
    overflow: hidden;
}

.glbtn{
    width: 210px;
    border-radius: 5px !important;
    background-color: #de4c33 !important;
    color: white !important; 
    font-weight: bold !important;
    font-size: small !important;
} 
.linkedinbtn{
    width: 210px !important;
    margin-left: 0px!important;
}
@media only screen and (min-width: 1000px) {
    .linkedinbtn{
        margin-left: 75px!important;
    } 
}
.kep-login-facebook.metro{
    width: 210px;
    border-radius: 5px !important;
    height: 50px;
    padding: 10px !important;
    font-size: small;
    font-weight: bold;
}
.kep-login-facebook .fa{
    
}
.signtext{
    font-size: small;
    font-weight: bold;
}
.svg{
    height: 18px !important;
}
