.hot-resource-form {
    
    background-color: rgba(0,0,0,.5);
}

.hot-jobs-container {
    background-color: #000;
    padding: 30px;
}
.hot-jos{
    width: 100%;
    height: 75px;
    background-color: rgba(255,255,255,.1);
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
}
.cur_pointer{
    text-decoration: none;

}
.btn-reset{
    width: 100%!important;
}
.hot-jos-button {
    width: 100%;
}
.hot{
    color: white;
    font-weight: bold;
    background-color: rgba(0,0,0,.5);
    justify-content: right;
}
.cur_pointer:hover .star_icon{
        transform: scale(1.5);
        color: #0e6ead;
    }
    .cur_pointer:hover{
        text-decoration: none;
        color: white;
    }
.star_icon{
    color:white;
    padding-left: 2px;
}
.pl-15{
    padding-left: 15px;
}
.register{
   border: 1px solid #e9ecef;
   border-radius:5px;
   background-color: white;

}
.inp{
    padding: 20px;
}
.form_container{
    max-width: 730px!important;
    width: 100%!important;
}
.required-field-block .required-icon {
    display: inline-block;
    border-color: rgba(255, 132, 132, 0.69);
    border-radius: 0.325em;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: background 0.1s linear;
    -moz-transition: background 0.1s linear;
    transition: background 0.1s linear;
    background-color: transparent;
    bottom: 0em;
    position: absolute;
    top: 0em;
    right: 0em;
    z-index: 10;
    width: 30px;
    text-align: center;
    -webkit-transition: color 0.2s ease;
    -moz-transition: color 0.2s ease;
    transition: color 0.2s ease;
    overflow: hidden;
}
.right_align{
    text-align: right!important;
    margin-top: 1rem;
}
.align_left{
    text-align: left!important;
    margin-top: 1rem;
}
.required-field-block {
    position: relative;
}
.required-field-block .required-icon:after {
    position: absolute;
    content: "";
    right: 1px;
    bottom: 1px;
    z-index: -1;
    width: 0em;
    height: 0em;
    border-top: 0em solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 0em solid transparent;
    border-right-color: inherit;
    -webkit-transition: border-color 0.2s ease;
    -moz-transition: border-color 0.2s ease;
    transition: border-color 0.2s ease;
    transform: rotate(90deg);
}
.required{
    color: rgb(211, 14, 14);
}
.label_style{
    font-size: 13px;
    font-weight: 500;
    margin-top:0.5rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.border-none{
    border-radius: 3px!important;
    font-size: 13px;
}
.nav-tabs{
    max-width: 602px;
    margin-left: 15px;
    border-bottom: none;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: white;
    border: none;
    color: black;
}
.nav-tabs .nav-link {
    background-color: rgba(0,0,0,.5);
    color: white;
    border-right: 1px solid rgba(0,0,0,.5);
}
.modal{
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
}
.table-bordered td{
    text-overflow: ellipsis;
}
.react-bootstrap-table-pagination{
    font-size: 11px!important;
}
.react-bootstrap-table-pagination-total{
    font-size: 13px!important;
}
.css-1s2u09g-control{
    height: 25px;
}
.css-rwh6u6-ValueContainer{
    height: 35px!important;
}
.css-14el2xx-placeholder{
    color: hsl(0, 0%, 50%);
    font-weight: 400;
}
#pageDropDown {
    font-size: 11px;
}
.react-bs-table-sizePerPage-dropdown .dropdown-menu{
    min-width: 5rem;
    font-size: 12px;
}
.workauth{
    overflow:hidden; 
    white-space:nowrap; 
    text-overflow:ellipsis;
}
