.hot-resource-form .form-row{
    width: 100%;
}
.hot-resource-form .form-row .form-group{
    width: 20%;
}
.form-inline .form-group{
    width: 25%;
}

.form-control {
    border-radius: 0;
}