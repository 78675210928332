@media (max-width: 767px) {
    .layout-header {
        background-color: #fff;
        min-height: 60px;
    }

    .container {
        width: 100%;
    }

    .page-group {
        padding-top: 60px;
    }

    .logo {
        max-width: 140px;
    }

    .navbar-collapse {
        position: absolute;
        left: 0;
        top: 60px;
        right: 0;
        background-color: #fff;
    }

    .header-login-buttn {
        position: absolute;
        right: 15px;
        top: 10px;
        padding: .375rem .75rem;
    }

    .services-button {
        margin-bottom: 15px;
    }

    .card-service {
        margin-bottom: 20px;
    }

    .hot-resource-form {
        height: auto;
        padding-bottom: 15px;
        padding-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .page-section-carousel {
        height: auto;
    }

    .container-carousel {
        padding-top: 20px;
    }

    .container-carousel h1 {
        font-size: 20px;
    }

    .page-section-hot-resources {
        margin-top: 0;
    }

    .carousel-image {
        height: 100%;
    }

    .page-section-firstchild h1 {
        color: #000;
    }

    .footer .container {
        flex-wrap: wrap;
    }

    .footer .icon-social {
        margin-top: 5px;
    }

    .event-media .media {
        display: initial;
    }

    .event-media .media img {
        width: 100%;
    }

    #events .card-body,
    #training .card-body {
        padding-left: 0;
        padding-right: 0;
    }

    .layout-header .navbar-nav {
        margin-left: -20px;
        margin-right: -20px;
    }

    .layout-header.navbar-light .navbar-nav .nav-link {
        padding-left: 25px;
        padding-right: 25px;
    }

    .layout-header.navbar-light .navbar-nav .nav-link.active {
        background-color: #3da3e5;
    }

    .home-video {
        height: 250px;
    }

    .page-section-hot-resources {
        padding-top: 20px;
    }

    .container-carousel-overlay {
        top: 45%;
    }

    .logo {
        margin-top: -10px;
    }

    .navbar-light .navbar-toggler {
        border: none;
        padding: 0;
        margin-right: 15px;
        margin-top: 5px;
        position: relative;
        top: 0;
    }

    .navbar-toggler-icon {
        width: 20px;
        height: 20px;
    }

    .page-section-about .overlay-container {
        position: relative;
        margin-top: 0;
        padding-top: 30px;
    }

    .page-section-about .img-about {
        position: absolute;
        bottom: 0;
        top: 25px;
        display: none;

    }

    .page-section-about iframe {
        margin-top: 30px;
    }

    .section-client-logo {
        justify-content: flex-start;
    }

    .client-logo-col {
        width: 50%;
    }

    .client-logo-col img {
        max-width: 100%;
    }

    .client-logo {
        width: 100%;
    }
    .page-section-testimonials .media {
        flex-direction: column;
        align-items: center;
    }
    .page-section-testimonials .media-body {
        padding-left: 15px;
        margin-top: 15px;
    }
    

.page-section-testimonials .splide__arrow {
    background-color: transparent;
}
.page-section-testimonials .splide__arrow--prev {
    left: -10px;
}
.page-section-testimonials .splide__arrow--next {
    right: -10px;
}
.page-section-testimonials .splide{
    padding-left: 25px !important;
    padding-right: 25px !important;
}
}

@media (min-width: 768px) {
    .layout-header {
        background-color: #fff;
    }

    .container {
        width: 100%;
    }

    .layout-header.navbar-light .navbar-nav .nav-link {
        padding-left: 14px;
        padding-right: 14px;
    }

    .header-login-buttn {
        position: absolute;
        right: 15px;
        top: 10px;
    }

    .hot-resource-form {
        height: auto;
        padding: 15px;
    }

    .col-lg-100 {
        width: 50px;
    }

    .col-lg-100 .btn-find {
        min-width: 60px;
    }

    .page-section-firstchild h1 {
        color: #000;
        margin-bottom: -9px;
    }

    .page-section-firstchild {
        margin-top: -300px;
    }

    .page-section-firstchild h1 {
        color: #fff;
        margin-bottom: -9px;
    }

    .service-text {
        max-width: 112px;
    }

    .overlay-container {
        position: relative;
    }

    .page-section-about .img-about {
        position: absolute;
    }
    .page-section-testimonials .container {
        max-width: 75%;
    }
}

@media (min-width: 992px) {
    .layout-header {
        background-color: transparent;
    }

    .container {
        width: 100%;
    }

    .header-login-buttn {
        position: static;
    }

    .row-grid10 .col-lg-2 {
        width: 20%;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }

    .hot-resource-form {
        height: 88px;
    }

    .page-section-firstchild {
        margin-top: -400px;
    }

    .page-section-firstchild h1 {
        color: #fff;
        margin-bottom: -9px;
    }

    .container-960 {
        width: 900px;
    }

    .service-text {
        max-width: 112px;
    }

    .row-x-large {
        margin-left: -35px;
        margin-right: -35px;
    }

    .row-x-large .col-md-6 {
        padding-left: 35px;
        padding-right: 35px;
        padding-bottom: 60px;
    }

    .overlay-container {
        position: relative;
    }

    .page-section-about .img-about {
        position: absolute;
    }
    .page-section-testimonials .container {
        max-width: 75%;
    }
   
}

@media (min-width: 1200px) {

    .container {
        width: 1200px;
        max-width: inherit;
    }

    .container-960 {
        width: 900px;
    }

    .header-login-buttn {
        position: static;
    }

    .row-grid10 .col-xl-2 {
        width: 20%;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }

    .page-section-service .row.row-grid10 {
        margin-left: -6px;
        margin-right: -6px;
    }

    .page-section-service .row.row-grid10 .col-xl-2 {
        padding-left: 6px;
        padding-right: 6px;
    }

    .col-xl-100 {
        width: 100px;
        flex: 0 0 130px;
        max-width: 100px;
    }

    .hot-resource-form {
        height: 88px;
        padding-left: 40px;
        padding-right: 40px;
    }

    .page-section-hot-resources {
        margin-top: -144px;
    }


    .card-subpage .card-body {
        padding: 50px;
    }

    .page-section-firstchild {
        margin-top: -600px;
    }

    .page-section-firstchild h1 {
        color: #fff;
        margin-bottom: -10px;
    }

    .service-text {
        max-width: inherit;
    }

    .overlay-container {
        position: absolute;
    }

    .page-section-about .img-about {
        position: relative;
    }
    .page-section-testimonials .container {
        max-width: 100%;
    }
}