.section-title {
    font-size: 40px;
    font-weight: 700;
    padding: 24px 0;
}
.text-weight-medium {
    font-weight: 500;
}
h2,.h2 {
    font-size: 30px;
    font-weight: 300;
}

.h5 {
    font-size: 18px;
}

.subsection-title {
    margin-bottom: 35px;
}